/*
OnlineOpinion v5.8.2
Released: 02/27/2014. Compiled 04/23/2014 09:51:37 AM -0500
Branch: CLIENTREQ-219 Apr
Components: Full
UMD: disabled
The following code is Copyright 1998-2014 Opinionlab, Inc.  All rights reserved. Unauthorized use is prohibited. This product and other products of OpinionLab, Inc. are protected by U.S. Patent No. 6606581, 6421724, 6785717 B1 and other patents pending. http://www.opinionlab.com
*/
/*global OOo, window*/
import get from "lodash/get";

(function (w, o) {
    'use strict';

/* Inline configuration */
    var OpinionLabInit = function () {
      const appState = JSON.parse(state.textContent);

    	/* Capture search keyword */
    	var resultsBox = document.getElementById('searchresultsfor'),
    	    searchNode,
    	    searchTerm,
    	    getQuery = function (name, url) {
    	        if (!url) {
    	          url = window.location.href;
    	        }
    	        name = name.replace(/[\[\]]/g, "\\$&");
    	        var regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)"),
    	            results = regex.exec(url);
    	        if (!results) return null;
    	        if (!results[2]) return '';
    	        return decodeURIComponent(results[2].replace(/\+/g, " "));
    	    };

    	if (!!resultsBox) {
    	    searchNode = resultsBox.getAttributeNode('data-search-term');
    	}

    	if (!!searchNode) {
    	    searchTerm = searchNode.value;
    	}

    	if (!searchTerm) {
    	    searchTerm = getQuery('q');
    	}

        var qmsid = o.readCookie('QuantumMetricSessionID');
        w.cv = {
        	searchTerm: typeof searchTerm !== "undefined" ? searchTerm : "",
            s_vi: o.readCookie('s_vi'),
            trackExternalLinks: typeof s !== 'undefined' ? (typeof s.trackExternalLinks !== 'undefined' ? s.trackExternalLinks : '') : '',
            pageName: typeof s !== 'undefined' ? (typeof s.pageName !== 'undefined' ? s.pageName : '') : '',
            ucId: typeof utag_data_dt !== 'undefined' ? utag_data_dt.universal_customer_id !== undefined ? utag_data_dt.universal_customer_id : '' : '',
            customerCountry: typeof utag_data_dt !== 'undefined' ? typeof utag_data_dt.customer_country !== 'undefined' ? utag_data_dt.customer_country : '' : '',
            countryCode: typeof utag_data_dt !== 'undefined' ? typeof utag_data_dt.country_code !== 'undefined' ? utag_data_dt.country_code : '' : '',
            qmSessionId: qmsid,
            qmSubscriptionName: 'neimans',
            qmReplayUrl: 'https://neimans.quantummetric.com/#/users/search?autoreplay=true&replaystart=-20&ts=7days&qmSessionCookie=' + qmsid
        };

        o.oo_feedback = new o.Ocode({
            tealeafCookieName: 'TLTSID',
            customVariables: w.cv
        });

        o.oo_feedback_filters = new o.Ocode({
            referrerRewrite: {
                searchPattern: /:\/\/[^\/]*/,
                replacePattern: '://filters.neimanmarcus.com'
            },
            tealeafCookieName: 'TLTSID',
            customVariables: w.cv
        });

        o.oo_feedback_wky = new o.Ocode({
            referrerRewrite: {
                searchPattern: /:\/\/[^\/]*/,
                replacePattern: '://wky.neimanmarcus.com'
            },
            tealeafCookieName: 'TLTSID',
            customVariables: w.cv
        });

        o.oo_search = new o.Ocode({
            referrerRewrite: {
                searchPattern: /:\/\/[^\/]*/,
                replacePattern: get(appState, 'opinionLab.oo_search.referrerRewrite.replacePattern', null)
            },
            tealeafCookieName: 'TLTSID',
            customVariables: w.cv
        });

        if (!!jQuery) {
            jQuery('#show-desktop-tablet #siteFeedBack').on('click', function() {
                o.oo_feedback.show();
            });
            jQuery('#siteFeedBack').on('click', function() {
                o.oo_feedback.show();
            });
        }

        o.UpdateQuantumMetric = function () {
            if (!!w.cv) {
                w.cv.qmSessionId = o.readCookie('QuantumMetricSessionID');
                w.cv.qmReplayUrl = 'https://' + w.cv.qmSubscriptionName + '.quantummetric.com/#/users/search?autoreplay=true&replaystart=-20&ts=7days&qmSessionCookie=' + w.cv.qmSessionId;
            }
        };

        if (typeof OOo !== 'undefined' && typeof OOo.releaseDetails !== 'object') { OOo.releaseDetails = []; }
        OOo.releaseDetails.push({
              author: 'JL',
              timeStamp: '03/05/2019, 15:24:04',
              fileName: 'oo_conf_inline.js',
              fileVersion: '3.0',
              ticketNumber: 'DYN-1016703-NeiMarc',
              gitDiff: '3c89a80ab53d2ed21700399ea3e4699e1b76dff1'
        });
    };

    o.addEventListener(w, 'load', OpinionLabInit, false);

})(window, OOo);